import { NewExchange, LockFundsRequest } from '@/DataModels/NewExchangeWithUser.js'
import { SignService } from '@/Services/SignService'
import { actionSignature } from '@/Methods/ActionSignature'
import { TransactionSignatures, offerSignatures } from '@/modules/Signatures'

export const CreateNewExchangeWithUser = data => {
  return new Promise(function (resolve, reject) {
    let signServices = new SignService(data.key, localStorage.getItem('user_id'))
    let options = new NewExchange(
      data.RecipientAccount,
      data.RecipientAmount,
      data.RecipientCurrency,
      data.RecipientUserID,
      data.RecipientWallet,
      data.SenderAccount,
      data.SenderAmount,
      data.SenderCurrency,
      data.SenderUserID,
      data.SenderWallet,
      data.OfferRecordStatus)
    let blockOption = new LockFundsRequest(
      data.SenderAmount,
      data.SenderCurrency,
      data.RecipientAccount,
      data.RecipientUserID,
      data.RecipientWallet,
      data.SenderAccount,
      data.SenderUserID,
      data.SenderWallet,
      data.ProcessType)

    let createSignedRequest = signServices.createSignedRequest(
      actionSignature(offerSignatures.channel, offerSignatures.chaincode, offerSignatures.action.addOffer),
      options)
    let createSignedRequestSecut = signServices
      .createSignedRequest(
        actionSignature(TransactionSignatures.channel, TransactionSignatures.chaincode, TransactionSignatures.action.lockFundsOnAccount),
        blockOption)
    let newExchangeData = {
      offer_request: createSignedRequest,
      txn_funds_lock_input: createSignedRequestSecut
    }
    resolve(newExchangeData)
    reject(Error)
  })
}
