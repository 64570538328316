import { SignService } from '@/Services/SignService'
import { EthereumCodeCCSignatures } from '@/modules/Signatures'
import { actionSignature } from '@/Methods/ActionSignature'

export const ExternalEthWallet = data => {
  let option = {
    currency_amount: {
      amount: data.amount,
      currency_type: data.currency
    },
    sender_address: data.ethWallet
  }
  let signService = new SignService(data.key, localStorage.getItem('user_id'))
  let signedData = signService.createSignedRequest(
    actionSignature(EthereumCodeCCSignatures.channel, EthereumCodeCCSignatures.chaincode, EthereumCodeCCSignatures.action.addCode),
    option
  )
  return signedData
}
