/* eslint-disable */
import { Amount } from '@/DataModels/Amount'
import { NewInUser } from '@/DataModels/NewInUser'
import { NewOutUser } from '@/DataModels/NewOutUser'

export class NewTransfer {
  constructor(amount, currency_type, in_account_id, in_user_id, in_wallet_id, out_account_id, out_user_id, out_wallet_id, process_type) {
    this.amount = new Amount(amount, currency_type)
    this.in_user = new NewInUser(in_account_id, in_user_id, in_wallet_id)
    this.out_user = new NewOutUser(out_account_id, out_user_id, out_wallet_id)
    this.process_type = process_type
  }
}
