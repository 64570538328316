import { SignService } from "@/Services/SignService";
import { NewExchange } from "@/DataModels/NewExchangeWithUser";
import { actionSignature } from "@/Methods/ActionSignature";
import { offerSignatures } from "@/modules/Signatures";

export default function (data) {
  return new Promise((resolve, reject) => {
    const signService = new SignService(data.key, localStorage.getItem('user_id'))
    const options = new NewExchange(
      data.RecipientAccount,
      data.RecipientAmount,
      data.RecipientCurrency,
      data.RecipientUserID,
      data.RecipientWallet,
      data.SenderAccount,
      data.SenderAmount,
      data.SenderCurrency,
      data.SenderUserID,
      data.SenderWallet,
      data.OfferRecordStatus
    )
    const createSignedRequest = signService.createSignedRequest(
      actionSignature(offerSignatures.channel, offerSignatures.chaincode, offerSignatures.action.addOffer),
      options
    )
    resolve(createSignedRequest)
    reject(Error)
  })
}
