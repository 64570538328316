import { SignService } from '@/Services/SignService'
import { accountSignatures } from '@/modules/Signatures'
import { actionSignature } from '@/Methods/ActionSignature'

export const createWallet = data => {
  let option = {
    'type': 'MAIN'
  }
  let signService = new SignService(data.key, data.login)
  let signedData = signService.createSignedRequest(
    actionSignature(accountSignatures.channel, accountSignatures.chaincode, accountSignatures.action.addWallet),
    option
  )
  return signedData
}
