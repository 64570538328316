/* eslint-disable */
String.prototype.hashCode = function () {
  let hash = 0;
  if (this.length === 0) return hash;
  for (let i = 0; i < this.length; i++) {
    let char = this.charCodeAt(i);
    hash = ((hash << 5) - hash) + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
};

/**
 * Глобальная функция для создания подписи действия пользователя
 * @param channel - название канала
 * @param chaincode - название чейнкода
 * @param action - активное действие
 * @returns {number} - строка с подписью
 */
export let actionSignature = (channel, chaincode, action) => {
  return (channel + chaincode + action).hashCode()
};

let f = function () {
  let hash = 0;
  if (this.length === 0) return hash;
  for (let i = 0; i < this.length; i++) {
    let char = this.charCodeAt(i);
    hash = ((hash << 5) - hash) + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
}
