import { Amount } from './Amount'
import { SignService } from '@/Services/SignService'
import { actionSignature } from '@/Methods/ActionSignature'
import { TransactionSignatures } from '@/modules/Signatures'

/**
 *
 */
export class NewExchange {
  constructor (RecipientAccount, RecipientAmount, RecipientCurrency, RecipientUserID, RecipientWallet, SenderAccount, SenderAmount, SenderCurrency, SenderUserID, SenderWallet, OfferRecordStatus) {
    this.in_info = new InInfo(RecipientAccount, RecipientAmount, RecipientCurrency, RecipientUserID, RecipientWallet)
    this.offer_record_status = OfferRecordStatus
    this.out_info = new OutInfo(SenderAccount, SenderAmount, SenderCurrency, SenderUserID, SenderWallet)
  }
}

/**
 *
 */
export class LockFundsRequest {
  constructor (SenderAmount, SenderCurrency, RecipientAccount, RecipientUserID, RecipientWallet, SenderAccount, SenderUserID, SenderWallet, ProcessType) {
    this.amount = new Amount(SenderAmount, SenderCurrency)
    this.in_user = new NewInUser(RecipientAccount, RecipientUserID, RecipientWallet)
    this.out_user = new NewOutUser(SenderAccount, SenderUserID, SenderWallet)
    this.process_type = ProcessType
  }

  getSignedRequest (key, userId) {
    let signService = new SignService(key, userId)
    let actionId = actionSignature(TransactionSignatures.channel, TransactionSignatures.chaincode, TransactionSignatures.action.lockFundsOnAccount)
    return signService.createSignedRequest(actionId, this)
  }
}

class InInfo {
  constructor (accountId, amount, currencyType, userId, userWallet) {
    this.account_id = accountId
    this.amount = new Amount(amount, currencyType)
    this.user_id = userId
    this.wallet_id = userWallet
  }
}

class OutInfo {
  constructor (accountId, amount, currencyType, userId, userWallet) {
    this.account_id = accountId
    this.amount = new Amount(amount, currencyType)
    this.user_id = userId
    this.wallet_id = userWallet
  }
}

class NewInUser {
  constructor (account, user, wallet) {
    this.account_id = account
    this.user_id = user
    this.wallet_id = wallet
  }
}

class NewOutUser {
  constructor (account, user, wallet) {
    this.account_id = account
    this.user_id = user
    this.wallet_id = wallet
  }
}
