import { SignService } from '@/Services/SignService'
import { accountSignatures } from '@/modules/Signatures'
import { actionSignature } from '@/Methods/ActionSignature'

export const AddBitcoinExternalWallet = (data) => {
  return new Promise(function (resolve, reject) {
    let signServices = new SignService(data.key, localStorage.getItem('user_id'))
    let options = {
      currency_type: data.currency,
      type: 'ADDITIONAL',
      wallet_id: data.mainWallet
    }
    let addBitcoinExternalWallet = signServices.createSignedRequest(actionSignature(accountSignatures.channel, accountSignatures.chaincode, accountSignatures.action.addAccount), options)
    resolve(addBitcoinExternalWallet)
    reject(Error)
  })
}
