import { SignService } from '@/Services/SignService'
import { accountSignatures } from '@/modules/Signatures'
import { actionSignature } from '@/Methods/ActionSignature'

export const ExternalBitcoinWallet = data => {
  let option = {
    currency_type: data.currency,
    type: 'ADDITIONAL',
    wallet_id: data.mainWallet
  }
  let signService = new SignService(data.key, localStorage.getItem('user_id'))
  let signedData = signService.createSignedRequest(
    actionSignature(accountSignatures.channel, accountSignatures.chaincode, accountSignatures.action.addAccount),
    option
  )
  return signedData
}
