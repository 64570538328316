import { SignService } from '@/Services/SignService'
import { referralSignatures } from '@/modules/Signatures'
import { actionSignature } from '@/Methods/ActionSignature'

export const NewProgram = data => {
  return new Promise(function (resolve, reject) {
    let signServices = new SignService(data.key, localStorage.getItem('user_id'))
    let options = {
      name: data.name,
      promocode: ''
    }
    let newProgram = signServices.createSignedRequest(actionSignature(referralSignatures.channel, referralSignatures.chaincode, referralSignatures.action.addReferralProgram), options)
    resolve(newProgram)
    reject(Error)
  })
}
