import { SignService } from '@/Services/SignService'
import { KycCCSignatures } from '@/modules/Signatures'
import { actionSignature } from '@/Methods/ActionSignature'

export const createStep = data => {
  return new Promise(function (resolve, reject) {
    let signServices = new SignService(data.key, localStorage.getItem('user_id'))
    let options = data.data
    let sendingStep = signServices.createSignedRequest(actionSignature(KycCCSignatures.channel, KycCCSignatures.chaincode, KycCCSignatures.action.acceptIndividualFormStep), options)
    resolve(sendingStep)
    reject(Error)
  })
}
