import { SignService } from '@/Services/SignService'
import { TransactionSignatures } from '@/modules/Signatures'
import { actionSignature } from '@/Methods/ActionSignature'
import { NewTransfer } from '@/DataModels/NewTransfer'

/**
 * Подпись для создания трансфера
 * @param data - данные для создания подписи
 * @returns {Promise} - подписанный объект
 */
export const CreateNewTransfer = (data) => {
  return new Promise(function (resolve, reject) {
    let signService = new SignService(data.key, localStorage.getItem('user_id'))
    let options = new NewTransfer(
      data.RecipientAmount,
      data.RecipientCurrency,
      data.RecipientAccount,
      data.RecipientUserID,
      data.RecipientWallet,
      data.SenderAccount,
      data.SenderUserID,
      data.SenderWallet,
      data.ProcessType)
    let newTransfer = signService.createSignedRequest(actionSignature(TransactionSignatures.channel, TransactionSignatures.chaincode, TransactionSignatures.action.lockFundsOnAccount), options)
    resolve(newTransfer)
    reject(Error)
  })
}
