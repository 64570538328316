export const GetOneCommission = (data, operation) => {
  let resultCommission = 0
  let resultCommissionCryptoenter = 0
  data.commission_list.forEach(item => {
    if (item.process_type === operation) {
      resultCommission = item.commission_percent
    }
  })
  data.cryptoenter_commission_list.forEach(item => {
    if (item.process_type === operation) {
      resultCommissionCryptoenter = item.commission_percent
    }
  })
  return { commissionPercentage: resultCommission, commissionCryptoenter: resultCommissionCryptoenter }
}
